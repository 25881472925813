import Cookies from "js-cookie"
import jwtDecode from "jwt-decode"
import { prefetchOffices } from "queries/offices"
import { queryCache, useQuery } from "react-query"
import { api } from "../utils/api"
import { actionCreator } from "./actionCreator"
import { toForm } from "../utils"
import { useSelector } from "react-redux"
import { serviceId } from "../reducers/category"

const cookiesSetting = { expires: 30 }

export const operation = {
  login: (data) => async (dispatch) => {
    const res = await api.post("/auth/login", data)
    const token = res.data.token
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`
    Cookies.set(`token`, token, cookiesSetting)
    prefetchOffices()
    dispatch(actionCreator.setJwtData(jwtDecode(token)))
    dispatch(actionCreator.setPage("content"))
  },
  authentication: (data, setError, setPage, cb) => (dispatch) => {
    api
      .post("/auth/login", data)
      .then((response) => {
        const token = response.data.token
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`
        Cookies.set(`token`, token, cookiesSetting)
        dispatch(actionCreator.setJwtData(jwtDecode(token)))
        setPage("content")
      })
      .catch((err) => {
        if (err.response.status === 401) {
          cb()
          setError(true)
          setTimeout(() => setError(false), 3000)
        }
      })
  },
  logout: (history, setPage) => (dispatch) => {
    api
      .get("/auth/logout")
      .then((response) => {
        Cookies.remove(`token`)
        dispatch(actionCreator.setJwtData({}))
        history.push("/")
        setPage("auth")
        queryCache.clear()
      })
      .catch(console.error)
  },
  getUser: (id) => (dispatch) => {
    api
      .get(`/user/${id}`)
      .then((response) => {
        dispatch(actionCreator.setUser(response.data))
      })
      .catch(console.error)
  },
  getOffice: (id) => (dispatch) => {
    api
      .get(`/office/${id}/get`)
      .then((response) => {
        dispatch(actionCreator.setOffice(response.data))
      })
      .catch(console.error)
  },
}
export function ServiceCount(officeID, serviceName, all = false) {
  const state = useSelector(state => state)
  const serviceID = serviceId(state, serviceName)
  const { data: data } = useQuery([officeID, serviceID], () => fetchServiceData(serviceID, officeID, all), {
    suspense: true,
  })
  return data
}
export async function fetchServiceData(serviceID, officesIDS, all) {
  const res = await api.get(`/sale/stat`, {
    params: {
      officeID: officesIDS,
      sliceType: "SERVICE",
      serviceID: serviceID
    }
  })
  const count = res.data[0]?.totalAmount ?? 0

  return all ? res.data[0] : count
}

export function Stat(params) {
  const keyParams = Object.entries(params)
  const { data: data } = useQuery(keyParams, () => fetchStat(params), { suspense: true })
  return data
}

export async function fetchStat(params) {
  const res = await api.get("/sale/stat", { params: params })
  return res.data
}
export function giveTotalValue(data) {

  return data[0]?.totalValue ?? 0
}
export function useMetric(indicatorID, userID) {
  const { data: data } = useQuery(["metrics", indicatorID, userID], () => getMetricValue(indicatorID, userID), { suspense: true })
  return data[0]
}
export function useMetricOffice(indicatorID, officeID, jobtitleID) {
  const { data: data } = useQuery(["metricsOffice", indicatorID, officeID, jobtitleID], () => getMetricValueOffice(indicatorID, officeID, jobtitleID), { suspense: true })
  return data
}
export async function getMetricValue(indicatorID, userID) {
  const res = await api.get("/indicator/values", { params: { indicatorID: indicatorID, userID: userID } })
  return res.data
}
async function getMetricValueOffice(indicatorID, officeID, jobtitleID) {
  const res = await api.get("/indicator/values", { params: { indicatorID: indicatorID, officeID, jobtitleID } })
  return res.data
}
export async function addMetric(data) {
  const res = await api.post("/indicator/add", [data])
  queryCache.invalidateQueries(["metrics", data.indicatorID, data.userID], { refetchInactive: true })

  return res.data
}
export async function fetchJobsList() {
  const res = await api.get("/jobtitle/list")
  return res.data
}
async function getMetric(obj) {
  const res = await api.get("/indicator/values", { params: obj })
  return res.data
}
export function useMetricObject(obj) {
  const arr = Object.entries(obj)
  const { data: data } = useQuery(["metricsGet", ...arr], () => getMetric(obj), { suspense: true })
  return data
}
