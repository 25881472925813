import "promise.allsettled/auto"
import "normalize.css"
import "overlayscrollbars/css/OverlayScrollbars.css"
import "react-select-me/lib/ReactSelectMe.css"
import "./assets/scss/main.scss"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import App from "./components/app/App"
import { rootReducer } from "./reducers"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

if (process.env.NODE_ENV === "development") {
    window.store = store
}

if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
        module.hot.accept("./reducers", () => {
            store.replaceReducer(require("./reducers").rootReducer)
        })
    }
}

function renderApp(App) {
    return ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        document.getElementById(`root`)
    )
}

renderApp(App)

if (module.hot) {
    module.hot.accept("./components/app/App", () => {
        const NextApp = require("./components/app/App").default
        renderApp(NextApp)
    })
}
