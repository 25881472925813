import React from "react"
import { roleSelector } from "reducers/jwt-data"
import BlueCup from "../../../assets/images/blue-cup.svg"
import GreyCup from "../../../assets/images/grey-cup.svg"
import { useTypedSelector } from "../../../reducers/useSelector"
import { api } from "../../../utils"

/** @param {Role} role */
export function reserveAccess(accessControl, targetOffice) {
    switch (accessControl.role) {
        case "ADMINISTRATOR":
            return true
        case "CURATOR":
            return targetOffice && targetOffice.groupID == accessControl.groupID
        case "DIRECTOR":
            return targetOffice && targetOffice.officeID == accessControl.officeID
        default:
            return false
    }
}

/** @typedef {import("../../../types").Role} Role */
/** @param {{preserve: boolean}} props */
export function ReserveCup({ preserve, id, reload }) {
    const role = useTypedSelector(roleSelector)

    const access = reserveAccess(role)
    if (!access && !preserve) return null
    return (
        <img
            src={preserve ? BlueCup : GreyCup}
            onClick={async () => {
                if (access) {
                    await api.put(`/user/${id}/personnel-reserve`)
                    reload()
                }
            }}
            alt=""
        ></img>
    )
}
