import React, { useState } from "react"
import clsx from "clsx"
import { formatAmount } from "utils/format"
import classes from "./box.module.scss"

export const Box = ({
    headernormal, headerz,
    percent, nonRelo, record,
    title, news, HeaderNone,
    className = "",
    popupClass = "",
    children,
    height,
    width = /** @type {string | number} */ (1),
    popupText = null,
    isUnavailable = false,
    withScroll = false,
    // toolbar represents top right buttons
    toolbar = /** @type {JSX.Element} */ (""),
    headerClass = "",
    ...props
}) => {
    const [isPopupOpen, setPopupOpen] = useState(false)
    const Headernormal2 = headernormal
    return (
        <div
            className={clsx(
                classes.Box,
                height === "full" && classes.BoxFullHeight,
                height === "fit" && classes.BoxFitHeight,
                height === "25%" && classes.height25,
                classes["Width" + width],
                popupClass && classes[popupClass],
                isUnavailable && classes.BoxUnavailable,
                withScroll && classes.WithScroll,
                className
            )}
            {...props}
        >
            {(title || toolbar) && (
                <div className={clsx(Headernormal2 ? classes.HeaderNormal : classes.Header, headerClass)}>
                    {title && (
                        <h2 className={clsx(classes.Title, toolbar && classes.WithToolbar)}>
                            {popupText && (
                                <button
                                    className={clsx(classes.PopupButton, classes.PopupButtonOpen)}
                                    onClick={() => setPopupOpen((p) => !p)}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.418" height="17.419" viewBox="0 0 17.418 17.419">
                                        <path
                                            id="info"
                                            d="M-1336.565,548.234a8.209,8.209,0,0,1,8.209-8.209,8.208,8.208,0,0,1,8.209,8.209,8.209,8.209,0,0,1-8.209,8.21A8.209,8.209,0,0,1-1336.565,548.234Zm1,0a7.218,7.218,0,0,0,7.209,7.21,7.217,7.217,0,0,0,7.209-7.21,7.216,7.216,0,0,0-7.209-7.209A7.217,7.217,0,0,0-1335.565,548.234Zm6.709,3.958v-5.63h1v5.63Zm0-6.744v-1.172h1v1.172Z"
                                            transform="translate(1337.065 -539.525)"
                                            fill="#b3b8bd"
                                            stroke="rgba(0,0,0,0)"
                                            strokeWidth="1"
                                        />
                                    </svg>
                                </button>
                            )}
                            {title}
                        </h2>
                    )}
                    {toolbar && <div className={classes.Toolbar}>{toolbar}</div>}
                    {record >= 0 && <div className={classes.FormatCount}>Рекорд: {formatAmount(record)}</div>}
                </div>
            )}

            {popupText && (
                <div className={clsx(classes.Popup, isPopupOpen && classes.PopupVisible)}>
                    <p className={classes.PopupText}>{popupText}</p>
                </div>
            )}
            <div className={clsx(classes.Content, percent || headerz ? classes.zIndex : "", Headernormal2 ? classes.ContentNormal : "", nonRelo ? classes.nonRelo : "", isPopupOpen && classes.ContentHidden)}>
                {children}
            </div>
        </div>
    )
}
